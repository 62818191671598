var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contact-list mt-2",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "contact-label" },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("contact.title_list", { size: _vm.contacts.length })
                )
            ),
          ]),
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          !_vm.readOnly
            ? _c(
                "div",
                { staticClass: "contact-toolbar border" },
                [
                  _vm.canAdd()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_ADD_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              { on: { click: _vm.contactAdd } },
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--grid-toolbar-button)",
                                  },
                                  attrs: { icon: ["far", "plus"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("contact.button.add")) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.canDelete()
                    ? [
                        _c(
                          "span",
                          { attrs: { id: "BTN_DELETE_" + _vm.id } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.selected.length === 0 },
                                on: { click: _vm.contactDelete },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "trash-can"] },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_DELETE_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("contact.button.delete")) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham contact-grid-height",
        staticStyle: { width: "100%" },
        attrs: {
          id: "contactGrid",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressContextMenu: "",
          suppressMultiSort: "",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("contact.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "contact.confirmation.delete_plural"
                      : "contact.confirmation.delete"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _vm.contactShow
        ? _c("ContactModal", {
            attrs: {
              holderId: _vm.holderId,
              id: _vm.contactId,
              show: _vm.contactShow,
              title: _vm.contactTitle,
              readOnly: _vm.readOnly || (_vm.exists && !_vm.canEdit()),
            },
            on: {
              "update:show": function ($event) {
                _vm.contactShow = $event
              },
              success: _vm.contactSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }