<template>
  <div :id="id" class="contact-list mt-2" style="width: 100%">
    <div class="contact-label">
      <div class="mb-2"> {{ $t('contact.title_list', { size: contacts.length }) }}</div>
    
      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>
      
      <div v-if="!readOnly" class="contact-toolbar border">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn  @click="contactAdd"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('contact.button.add') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="selected.length === 0" @click="contactDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>   
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('contact.button.delete') }}
          </b-popover>
        </template>     
      </div>
    </div>

    <ag-grid-vue style="width: 100%;" class="ag-theme-balham contact-grid-height" id="contactGrid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :rowData="rowData"
          :rowSelection="multiple? 'multiple':'single'"
          rowMultiSelectWithClick
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>

    <b-modal :title="$t('contact.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'contact.confirmation.delete_plural':'contact.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <ContactModal v-if="contactShow" :holderId="holderId" :id="contactId" :show.sync="contactShow" @success="contactSuccess" :title="contactTitle" 
      :readOnly="readOnly || (exists && !canEdit())" />
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { strRandom } from '@/helpers';
import { contactService } from '@/services';
import { columnDefSortFunc } from '@/views/management/script/common';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';

export default {
  name: 'ContactList',
  components: {
    'ag-grid-vue': AgGridVue,
    ContactModal: () => import('@/components/modal/ContactModal'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    'detailLinkCellRenderer': DetailLinkCellRenderer // eslint-disable-line vue/no-unused-components
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: 'Contact Selector'
    },
    contacts: {
      type: Array,
      default: () => []
    },
    holderId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }    
  },
  data: function() {
    return {
      id: `CONTACT_LIST_${strRandom(5)}`,
      permissionName: 'CONTACT',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      contactId: null,
      contactShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index + 1);
        }

        params.api.sizeColumnsToFit();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('contact.field.firstName'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: this.readOnly? false : true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 230,
        hide: false
      },
      {
        headerName: this.$t('contact.field.lastName'),
        field: 'lastName',
        minWidth: 230,
        hide: false
      },
      {
        headerName: this.$t('contact.field.position'),
        field: 'position',
        minWidth: 100,
        hide: false
      },
      {
        headerName: this.$t('contact.field.company'),
        field: 'company',
        minWidth: 100,
        hide: false
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.columnDefs.sort(columnDefSortFunc);

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
  },
  watch: {
    contacts: function(newValue) {
      if(this.gridApi) {
        this.rowData = newValue;
        this.gridApi.refreshCells({ force: true });
      }
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    contactTitle() {
      return this.contactId && this.contactId.indexOf('CONTACT_NEW') == -1? this.$t('contact.title_detail'): this.$t('contact.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('contact.grid.no_data') }</span>`;
    },
    exists() {
      return this.contactId != null && this.contactId.indexOf('CONTACT_NEW') == -1;
    }
  },
  methods: {
    onGridReady(/*params*/) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.rowData = this.contacts;
      this.detectOrientationChange();
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
      }, 100);
    },
    initModal() {
      this.gridApi.sizeColumnsToFit();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    openDetail(id){
      this.contactId = id;
      this.contactShow = true;
      this.resetAlert();
    },
    detailLinkLabel(params) {
      const self = this;
      const name = params && params.data && params.data.firstName? params.data.firstName : '';
      return name.trim().length == 0? self.$t('value_not_available'): name;
    },
    contactSuccess({ msg }) {
      this.resetAlert({ msg });
      this.$emit('success');
    },
    contactAdd() {
      this.contactId = null;
      this.contactShow = true;
      this.resetAlert();
    },
    contactDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk() {
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`contact.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await contactService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('contact.delete_partial');
          alertMsgDetailTitle = this.$t(`contact.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`contact.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`contact.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('contact.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.selected.length = 0;
        this.$emit('success');
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.resetAlert(alertPayload);
    }
  }
}


</script>

<style lang="scss">
  .contact-list {
    .contact-toolbar {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
        &:first-child {
          margin-left: 8px;
        }
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }

  .contact-grid-height {
    height: 150px;
    min-height: 150px;
  }  
</style>